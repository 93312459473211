import { LoginResponse } from '../models/LoginResponse';
import useAuth from './useAuth';

export default function useRefresh() {
  const { auth, setAuth, destroyAuth } = useAuth();

  async function refresh() {
    const refreshResponse = await fetch(
      'https://api.prod.beatthebookinc.com/api/v1/login/refresh',
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({ refreshToken: auth?.refreshToken ?? '' }),
      },
    );

    if (refreshResponse.ok) {
      const res: LoginResponse = await refreshResponse.json();
      setAuth(res);
      return;
    }

    destroyAuth();
    throw new Error('Unable to refresh');
  }

  return { refresh };
}
