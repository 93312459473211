import { Flex, HStack, Spacer, Text } from '@chakra-ui/react';
import { BetWithLeagueAndGame } from '../../../models/BetWithLeagueAndGame';
import { Identity } from '../../Profile/Identity';
import { BetOddsAndStatus } from './BetOdds';
import { BetSide } from '../../../models/BetSide';

export function TotalBet(props: { key: string; bet: BetWithLeagueAndGame }) {
  return (
    <Flex key={props.key} p={1} alignContent={'left'} width={'100%'}>
      <HStack>
        <Identity
          content={props.bet.game.awayTeam.avatar}
          displayName={`${props.bet.game.awayTeam.cityAbbreviation}`}
          size={'sm'}
        />
        <Text>{' @ '}</Text>
        <Identity
          content={props.bet.game.homeTeam.avatar}
          displayName={`${props.bet.game.homeTeam.cityAbbreviation}`}
          size={'sm'}
        />
        <Text fontSize={'sm'}>
          {props.bet.side === BetSide.over
            ? `o${props.bet.totalPoints}`
            : `u${props.bet.totalPoints}`}
        </Text>
      </HStack>
      <Spacer />
      <BetOddsAndStatus bet={props.bet} />
    </Flex>
  );
}
