import { Flex, Spacer } from '@chakra-ui/react';
import { BetWithLeagueAndGame } from '../../../models/BetWithLeagueAndGame';
import { Identity } from '../../Profile/Identity';
import { BetSide } from '../../../models/BetSide';
import { BetOddsAndStatus } from './BetOdds';

export function MoneylineBet(props: {
  key: string;
  bet: BetWithLeagueAndGame;
}) {
  const team =
    props.bet.side === BetSide.home
      ? props.bet.game.homeTeam
      : props.bet.game.awayTeam;

  return (
    <Flex key={props.key} p={1} width={'100%'}>
      <Identity
        content={team.avatar}
        displayName={`${team.cityAbbreviation}`}
        size={'sm'}
      />
      <Spacer />
      <BetOddsAndStatus bet={props.bet} />
    </Flex>
  );
}
