import {
  Box,
  Heading,
  Tabs,
  Tab,
  TabPanel,
  TabPanels,
  TabList,
  Text,
} from '@chakra-ui/react';
import { decodeToken } from 'react-jwt';
import Feed from '../Feed/Feed';
import useAuth from '../../hooks/useAuth';
import { UserRole } from '../../models/UserRole';
import { SportsbookPage } from '../Sportsbook/SportsbookPage';

export function Navbar() {
  const { auth } = useAuth();
  let userRoles: UserRole[] = [];

  if (auth) {
    userRoles = (decodeToken(auth.accessToken) as { userRoles: UserRole[] })
      .userRoles;
  }

  const showAdminTab = userRoles.includes(UserRole.admin);

  return (
    <Box p={1}>
      <Heading p={2} as='h1'>
        BTB Web Dashboard
      </Heading>
      <Tabs isLazy={true}>
        <TabList>
          <Tab>Feed</Tab>
          <Tab>Sportsbook</Tab>
          {/* <Tab>Profile</Tab> disabled for now */}
          {showAdminTab ? <Tab>Admin</Tab> : <></>}
        </TabList>

        <TabPanels>
          <TabPanel>
            <Feed />
          </TabPanel>
          <TabPanel>
            <SportsbookPage />
          </TabPanel>
          {/* <TabPanel>
            <ProfilePage />
          </TabPanel> */}
          {showAdminTab ? (
            <TabPanel>
              <Text>Admin panel will go here</Text>
            </TabPanel>
          ) : (
            <></>
          )}
        </TabPanels>
      </Tabs>
    </Box>
  );
}
