import { Flex, Spacer } from '@chakra-ui/react';
import { Team } from '../../models/Team';
import { Identity } from '../Profile/Identity';

export function GameTeams(props: { home: Team; away: Team }) {
  return (
    <Flex p={1} align={'left'} direction={'column'}>
      <Identity
        content={props.home.avatar}
        displayName={props.home.cityAbbreviation}
        size={'sm'}
      />
      <Spacer />
      <Identity
        content={props.away.avatar}
        displayName={props.away.cityAbbreviation}
        size={'sm'}
      />
    </Flex>
  );
}
