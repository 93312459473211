import { useEffect, useState } from 'react';
import useApiCall from '../../hooks/useApiCall';
import { League } from '../../models/League';
import { Game } from '../../models/Game';
import { Box, Flex, VStack, Spacer, Text } from '@chakra-ui/react';
import { GameComponent } from '../Game/GameComponent';

export function Sportsbook(props: { league: League }) {
  const { makeAuthenticatedCall } = useApiCall();
  const [games, setGames] = useState<Game[]>([]);

  useEffect(() => {
    const getGames = async () => {
      const res = await makeAuthenticatedCall<Game[]>(
        `/v1/sport/${props.league.sportName}/league/${props.league.name}/pregame`,
      );
      setGames(res);
    };

    getGames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VStack p={1}>
      <Flex padding={'10px'} width={'md'}>
        <Box ml={5} width={'20%'}>
          <Text fontWeight={'bold'}>TEAM</Text>
        </Box>
        <Spacer />
        <Flex width={'60%'}>
          <Box width={'20%'}>
            <Text align={'center'} fontWeight={'bold'}>
              ML
            </Text>
          </Box>
          <Spacer />
          <Box width={'35%'}>
            <Text align={'center'} fontWeight={'bold'}>
              SPREAD
            </Text>
          </Box>
          <Spacer />
          <Box width={'35%'}>
            <Text align={'center'} fontWeight={'bold'}>
              TOTAL
            </Text>
          </Box>
        </Flex>
      </Flex>
      {games.length === 0 ? <Text>No games at this time.</Text> : <></>}
      {games.map((game) => (
        <GameComponent game={game} />
      ))}
    </VStack>
  );
}
