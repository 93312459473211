import { BetWithLeagueAndGame } from '../../../models/BetWithLeagueAndGame';
import { BetType } from '../../../models/BetType';
import { MoneylineBet } from './MoneylineBet';
import { TotalBet } from './TotalBet';
import { SpreadBet } from './SpreadBet';

export function Bet(props: { key: string; bet: BetWithLeagueAndGame }) {
  switch (props.bet.betType) {
    case BetType.moneyline:
      return <MoneylineBet key={props.key} bet={props.bet} />;
    case BetType.spread:
      return <SpreadBet key={props.key} bet={props.bet} />;
    case BetType.total:
      return <TotalBet key={props.key} bet={props.bet} />;
    default:
      throw new Error('Unknown BetType');
  }
}
