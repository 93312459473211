import { HStack, Text } from '@chakra-ui/react';
import { BetWithLeagueAndGame } from '../../../models/BetWithLeagueAndGame';
import { BetStatus } from '../../../models/BetStatus';

export function BetOddsAndStatus(props: { bet: BetWithLeagueAndGame }) {
  let statusColor;
  switch (props.bet.status) {
    case BetStatus.win:
      statusColor = 'green';
      break;
    case BetStatus.loss:
      statusColor = 'red';
      break;
    default:
      statusColor = 'gray';
      break;
  }

  return (
    <HStack fontSize={'sm'}>
      <Text>{props.bet.odds > 0 ? `+${props.bet.odds}` : props.bet.odds}</Text>
      <Text>{' | '}</Text>
      <Text color={statusColor}>{props.bet.status.toLocaleUpperCase()}</Text>
    </HStack>
  );
}
