import { Flex, VStack, Text } from '@chakra-ui/react';
import { BetGroup } from '../../../models/BetGroup';
import { Bet } from '../Bet/Bet';

export default function BetGroupComponent(props: { betGroup: BetGroup }) {
  return (
    <VStack p={1}>
      {props.betGroup.bet.map((b) => (
        <Bet key={b.id} bet={b} />
      ))}
      <Flex justify={'right'} width={'100%'} fontSize={'sm'}>
        <Text>
          {props.betGroup.parlay
            ? `${props.betGroup.bet.length}-Leg Parlay | `
            : ''}{' '}
          Group Odds:{' '}
          {props.betGroup.betGroupOdds > 0
            ? `+${props.betGroup.betGroupOdds.toFixed(0)}`
            : props.betGroup.betGroupOdds.toFixed(0)}
        </Text>
      </Flex>
    </VStack>
  );
}
