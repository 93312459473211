import { useEffect, useState } from 'react';
import useApiCall from '../../hooks/useApiCall';
import { User } from '../../models/User';
import { Box, Text, VStack } from '@chakra-ui/react';
import { Identity } from '../Profile/Identity';

export function UserModal(props: { endpoint: string }) {
  const { makeAuthenticatedCall } = useApiCall();
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    const getUsers = async () => {
      const res = await makeAuthenticatedCall(props.endpoint);
      setUsers(res);
    };

    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VStack>
      {users.length === 0 ? (
        <Box p={1}>
          <Text>No data</Text>
        </Box>
      ) : (
        users.map((u) => (
          <Box width={'50%'} key={u.id}>
            <Identity
              content={u.avatar}
              displayName={u.displayName}
              secondName={u.username}
            />
          </Box>
        ))
      )}
    </VStack>
  );
}
