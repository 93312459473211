import { useState, useEffect } from 'react';
import useApiCall from '../../hooks/useApiCall';
import { PaginatedResponse } from '../../models/PaginatedResponse';
import { Comment } from '../../models/Comment';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Identity } from '../Profile/Identity';

export function CommentModal(props: { yeetId: string }) {
  const { makeAuthenticatedCall } = useApiCall();
  const [startIndex, setStartIndex] = useState<number>(0);
  const [endIndex, setEndIndex] = useState<number>(20);
  const [comments, setComments] = useState<PaginatedResponse<Comment>>();

  useEffect(() => {
    const getComments = async () => {
      const res = await makeAuthenticatedCall(
        `/v1/yeet/${props.yeetId}/comment?startIndex=${startIndex}&endIndex=${endIndex}`,
      );
      setComments(res);
    };

    getComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startIndex]);

  const last = () => {
    if (startIndex !== 0) {
      setStartIndex(startIndex - 20);
      setEndIndex(endIndex - 20);
    }
  };

  const next = () => {
    if (comments?.hasMoreData) {
      setStartIndex(startIndex + 20);
      setEndIndex(endIndex + 20);
    }
  };

  return (
    <VStack>
      {comments !== undefined ? (
        comments.data.length !== 0 ? (
          <>
            {(comments as unknown as PaginatedResponse<Comment>).data.map(
              (comment) => (
                <Flex align={'center'} width={'90%'}>
                  <Identity
                    content={comment.user.avatar}
                    displayName={comment.user.displayName}
                    secondName={comment.user.username}
                  />
                  <Box p={1}>
                    <Text>{comment.message}</Text>
                  </Box>
                  <Spacer />
                  <Box p={1}>
                    <Text fontSize={'xs'}>
                      {comment.createdAt.toString().split('T')[0]}
                    </Text>
                  </Box>
                </Flex>
              ),
            )}
            <ButtonGroup>
              <Button isDisabled={startIndex === 0} onClick={last}>
                Last
              </Button>
              <Button isDisabled={!comments.hasMoreData} onClick={next}>
                Next
              </Button>
            </ButtonGroup>
          </>
        ) : (
          <Box p={1}>
            <Text>No data</Text>
          </Box>
        )
      ) : (
        <Box p={1}>
          <Text>Loading...</Text>
        </Box>
      )}
    </VStack>
  );
}
