import { Yeet } from '../../models/Yeet';
import { Box, Flex, Text, Spacer, Image } from '@chakra-ui/react';
import { formatMentionText } from '../../util/formatMentionText';
import BetGroupComponent from '../Bet/BetGroup/BetGroupComponent';
import { Identity } from '../Profile/Identity';
import { BetInfo } from '../Bet/BetInfo/BetInfo';
import { YeetInfo } from './YeetInfo';

export default function YeetComponent(props: { yeet: Yeet }) {
  return (
    <Box
      id={props.yeet.id}
      borderWidth={'1px'}
      borderRadius={'lg'}
      padding={'10px'}
      width={'xl'}
      boxShadow={'md'}
    >
      <Flex align={'left'}>
        <Box p={1}>
          <Identity
            content={props.yeet.user.avatar}
            displayName={props.yeet.user.displayName}
            secondName={props.yeet.user.username}
          />
        </Box>
        <Spacer />
        <Box p={1}>
          <BetInfo betGroups={props.yeet.betGroups} />
        </Box>
      </Flex>
      {props.yeet.message ? (
        <Box p={1}>
          <Text fontSize={'sm'}>{formatMentionText(props.yeet.message)}</Text>
        </Box>
      ) : (
        <></>
      )}
      {props.yeet.content.length > 0 ? (
        <Flex justify={'center'} p={1}>
          {props.yeet.content.map((c) => (
            <Image
              key={c.contentUrl}
              alt={c.contentUrl}
              src={c.contentUrl}
              boxSize={300}
              borderRadius={'xl'}
            />
          ))}
        </Flex>
      ) : (
        <></>
      )}

      <Box p={1}>
        {props.yeet.betGroups.map((bg) => (
          <BetGroupComponent key={bg.id} betGroup={bg} />
        ))}
      </Box>
      <Box p={1}>
        <hr />
      </Box>
      <Box p={1}>
        <YeetInfo yeet={props.yeet} />
      </Box>
    </Box>
  );
}
