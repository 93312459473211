import { Stack, Text } from '@chakra-ui/react';
import { BetGroup } from '../../../models/BetGroup';
import { BetStatus } from '../../../models/BetStatus';

export function BetInfo(props: { betGroups: BetGroup[] }) {
  if (props.betGroups.length !== 1) {
    return <></>;
  }

  let statusColor;
  switch (props.betGroups[0].status) {
    case BetStatus.win:
      statusColor = 'green';
      break;
    case BetStatus.loss:
      statusColor = 'red';
      break;
    default:
      statusColor = 'gray';
      break;
  }

  return (
    <Stack gap={0}>
      <Text align={'right'} fontSize={'md'} color={statusColor}>
        {props.betGroups[0].status.toUpperCase()}
      </Text>
      <Text fontSize={'sm'}>
        {props.betGroups[0].parlay
          ? `Parlay (${props.betGroups[0].wager}u)`
          : `${
              props.betGroups[0].bet[0].betType.charAt(0).toUpperCase() +
              props.betGroups[0].bet[0].betType.slice(1)
            } (${props.betGroups[0].wager}u)`}
      </Text>
    </Stack>
  );
}
