import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Center, VStack, Text } from '@chakra-ui/react';
import useApiCall from '../../hooks/useApiCall';
import { PaginatedResponse } from '../../models/PaginatedResponse';
import { Yeet } from '../../models/Yeet';
import YeetComponent from '../Yeet/YeetComponent';

export default function Feed() {
  const { makeAuthenticatedCall } = useApiCall();
  const [feed, setFeed] = useState<PaginatedResponse<Yeet>>();
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(20);

  useEffect(() => {
    const getFeed = async () => {
      const res = await makeAuthenticatedCall(
        `/v1/feed?startIndex=${startIndex}&endIndex=${endIndex}`,
      );
      setFeed(res);
    };

    getFeed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startIndex]);

  const nextPage = () => {
    setEndIndex(endIndex + 20);
    setStartIndex(startIndex + 20);
  };

  const lastPage = () => {
    if (startIndex !== 0) {
      setEndIndex(endIndex - 20);
      setStartIndex(startIndex - 20);
    }
  };

  return (
    <Center>
      <VStack id='stack'>
        <Text>
          Viewing posts {startIndex + 1} to {endIndex}
        </Text>
        <ButtonGroup>
          <Button onClick={lastPage} isDisabled={startIndex === 0}>
            Last 20
          </Button>
          <Button onClick={nextPage} isDisabled={!feed?.hasMoreData}>
            Next 20
          </Button>
        </ButtonGroup>
        <VStack
          id='feed'
          justifyContent={'center'}
          padding={'10px'}
          gap={'10px'}
        >
          {feed ? (
            feed.data.map((y) => {
              return <YeetComponent key={y.id} yeet={y} />;
            })
          ) : (
            <></>
          )}
        </VStack>
        <ButtonGroup>
          <Button onClick={lastPage} isDisabled={startIndex === 0}>
            Last 20
          </Button>
          <Button onClick={nextPage} isDisabled={!feed?.hasMoreData}>
            Next 20
          </Button>
        </ButtonGroup>
      </VStack>
    </Center>
  );
}
