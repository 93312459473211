export function formatLeagueName(league: string): string {
  switch (league) {
    case 'nfl':
      return 'NFL';
    case 'mlb':
      return 'MLB';
    case 'nba':
      return 'NBA';
    case 'nhl':
      return 'NHL';
    case 'ncaa-football':
      return 'NCAAF';
    case 'ncaa-basketball':
      return 'NCAAB';
    default:
      throw new Error(`Unknown league ${league}`);
  }
}
