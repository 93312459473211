import { useState } from 'react';
import { decodeToken } from 'react-jwt';
import {
  Box,
  Center,
  useToast,
  Button,
  FormControl,
  FormLabel,
  Input,
  Heading,
  VStack,
  Flex,
  Spacer,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { LoginResponse } from '../../models/LoginResponse';
import { useNavigate } from 'react-router';
import { UserRole } from '../../models/UserRole';

async function makeLoginRequest(
  usernameOrEmail: string,
  password: string,
): Promise<LoginResponse> {
  const response = await fetch(
    'https://api.prod.beatthebookinc.com/api/v1/login',
    {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({
        usernameOrEmail,
        password,
        timezoneOffset: '-05:00',
      }),
    },
  );

  if (response.ok) {
    return response.json();
  }

  throw new Error(JSON.stringify(response));
}

export default function Login(props: {
  setAuth: (token: LoginResponse) => void;
}) {
  const navigate = useNavigate();
  const toast = useToast();

  const [usernameOrEmail, setUsernameOrEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [submitting, setSubmitting] = useState(false);
  const [seePassword, toggleSeePassword] = useState(false);

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setSubmitting(true);

    let loginResponse: LoginResponse;
    try {
      loginResponse = await makeLoginRequest(usernameOrEmail, password);
    } catch (err) {
      toast({
        title: 'Login Failed',
        description: 'Invalid username, email, or password',
        status: 'error',
        duration: 5000,
        isClosable: false,
      });
      setSubmitting(false);
      return;
    }

    setSubmitting(false);
    const payload: { userRoles: UserRole[] } | null = decodeToken(
      loginResponse.accessToken,
    );

    if (
      payload?.userRoles.includes(UserRole.admin) ||
      payload?.userRoles.includes(UserRole.grader)
    ) {
      props.setAuth(loginResponse);
      toast({
        title: 'Login Success',
        status: 'success',
        duration: 5000,
        isClosable: false,
      });
      return navigate('/');
    }

    toast({
      title: 'Login Failed',
      description: 'User is missing required permissions',
      status: 'error',
      duration: 5000,
      isClosable: false,
    });
    return;
  };

  return (
    <Center>
      <Box
        w='50%'
        id='center'
        padding={'10px'}
        borderWidth='1px'
        borderRadius='lg'
        overflow={'hidden'}
        alignContent={'center'}
      >
        <VStack padding='5px' margin={'5px'}>
          <Heading as='h1' size='xl'>
            Please Log In
          </Heading>
          <FormControl
            colorScheme='blue'
            onSubmit={handleSubmit}
            isDisabled={submitting}
          >
            <form>
              <VStack align={'left'}>
                <FormLabel>Username or Email</FormLabel>
                <Input
                  type='text'
                  onChange={(e) => setUsernameOrEmail(e.target.value)}
                />
                <FormLabel>Password</FormLabel>
                <Flex>
                  <Input
                    width={'90%'}
                    type={seePassword ? 'text' : 'password'}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <Spacer />
                  <Button onClick={() => toggleSeePassword(!seePassword)}>
                    {seePassword ? <ViewOffIcon /> : <ViewIcon />}
                  </Button>
                </Flex>
                <Button type='submit'>Submit</Button>
              </VStack>
            </form>
          </FormControl>
        </VStack>
      </Box>
    </Center>
  );
}
