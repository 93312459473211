import { useState } from 'react';
import {
  Box,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Yeet } from '../../models/Yeet';
import { CommentModal } from '../Modal/CommentModal';
import { UserModal } from '../Modal/UserModal';

export function YeetInfo(props: { yeet: Yeet }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalType, setModalType] = useState<string>();

  const openModal = (type: string) => {
    setModalType(type);
    onOpen();
  };

  const getModal = () => {
    if (!isOpen) {
      return;
    }
    switch (modalType) {
      case 'LIKES':
        return <UserModal endpoint={`/v1/yeet/${props.yeet.id}/like`} />;
      case 'RIDES':
        return <UserModal endpoint={`/v1/yeet/${props.yeet.id}/tail`} />;
      case 'FADES':
        return <UserModal endpoint={`/v1/yeet/${props.yeet.id}/fade`} />;
      case 'COMMENTS':
        return <CommentModal yeetId={props.yeet.id} />;
      default:
        throw new Error(`Unknown modal type ${modalType}`);
    }
  };

  return (
    <>
      <Flex>
        <Flex width={'60%'}>
          <Box>
            <Link fontSize={'14px'} onClick={() => openModal('LIKES')}>
              {props.yeet.numLikes} LIKES
            </Link>
          </Box>
          <Spacer />
          <Box>
            <Link fontSize={'14px'} onClick={() => openModal('RIDES')}>
              {props.yeet.numTails} RIDES
            </Link>
          </Box>
          <Spacer />
          <Box>
            <Link fontSize={'14px'} onClick={() => openModal('FADES')}>
              {props.yeet.numFades} FADES
            </Link>
          </Box>
          <Spacer />
          <Box>
            <Link fontSize={'14px'} onClick={() => openModal('COMMENTS')}>
              COMMENTS
            </Link>
          </Box>
        </Flex>
        <Spacer />
        <Box>
          <Text fontSize={'14px'}>{props.yeet.createdAt.toString()}</Text>
        </Box>
      </Flex>
      <Modal onClose={onClose} size={'lg'} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalType}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{getModal()}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
