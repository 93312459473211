import { useNavigate } from 'react-router';
import useAuth from './useAuth';
import useRefresh from './useRefresh';

export default function useApiCall() {
  const { auth } = useAuth();
  const { refresh } = useRefresh();
  const navigate = useNavigate();

  async function makeAuthenticatedCall<T>(
    endpoint: string,
    init?: RequestInit | undefined,
  ) {
    const response = await fetch(
      `https://api.prod.beatthebookinc.com/api${endpoint}`,
      {
        ...init,
        headers: {
          ...init?.headers,
          Authorization: `Bearer ${auth?.accessToken}`,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      },
    );

    if (response.ok) {
      return response.json();
    }

    if (response.status === 401) {
      try {
        await refresh();
      } catch {
        return navigate('/login');
      }
      return makeAuthenticatedCall<T>(endpoint, init);
    }

    if (response.status === 500 || response.status === 502) {
      return makeAuthenticatedCall<T>(endpoint, init);
    }

    throw new Error(
      `Error making network request: ${JSON.stringify(response)}`,
    );
  }

  return { makeAuthenticatedCall };
}
