import { useState } from 'react';
import { LoginResponse } from '../models/LoginResponse';

export default function useAuth() {
  function getAuth(): LoginResponse | null {
    const authString = sessionStorage.getItem('auth');
    const auth = authString ? JSON.parse(authString) : null;
    return auth;
  }

  const [auth, setAuth] = useState(getAuth());

  function saveAuth(loginRes: LoginResponse) {
    sessionStorage.setItem('auth', JSON.stringify(loginRes));
    setAuth(loginRes);
  }

  function destroyAuth() {
    sessionStorage.removeItem('auth');
    setAuth(null);
  }

  return { auth, setAuth: saveAuth, destroyAuth };
}
