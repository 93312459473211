import { Flex, HStack, Spacer, Text } from '@chakra-ui/react';
import { BetWithLeagueAndGame } from '../../../models/BetWithLeagueAndGame';
import { Identity } from '../../Profile/Identity';
import { BetSide } from '../../../models/BetSide';
import { BetOddsAndStatus } from './BetOdds';

export function SpreadBet(props: { key: string; bet: BetWithLeagueAndGame }) {
  const team =
    props.bet.side === BetSide.home
      ? props.bet.game.homeTeam
      : props.bet.game.awayTeam;
  const spread =
    props.bet.side === BetSide.home
      ? props.bet.pointSpreadHome
      : props.bet.pointSpreadAway;
  return (
    <Flex key={props.key} p={1} width={'100%'}>
      <HStack>
        <Identity
          content={team.avatar}
          displayName={`${team.cityAbbreviation}`}
          size={'sm'}
        />
        <Text fontSize={'sm'}>
          {(spread as number) > 0 ? `+${spread}` : spread}
        </Text>
      </HStack>
      <Spacer />
      <BetOddsAndStatus bet={props.bet} />
    </Flex>
  );
}
