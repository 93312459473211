import { Avatar, Box, HStack, VStack, Text } from '@chakra-ui/react';
import { Content } from '../../models/Content';

export function Identity(props: {
  content: Content | null;
  displayName: string;
  secondName?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}) {
  return (
    <Box padding={'3px'}>
      <HStack>
        {props.content ? (
          <Avatar
            name={props.displayName}
            src={props.content.contentUrl}
            size={props.size ?? 'md'}
          />
        ) : (
          <></>
        )}
        <VStack align={'left'} gap={'0px'}>
          <Text fontSize={props.size ?? 'md'}>{props.displayName}</Text>
          {props.secondName ? (
            <Text color={'blue'} fontSize={props.size ?? 'md'}>
              @{props.secondName}
            </Text>
          ) : (
            <></>
          )}
        </VStack>
      </HStack>
    </Box>
  );
}
