import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import useApiCall from '../../hooks/useApiCall';
import { useEffect, useState } from 'react';
import { League } from '../../models/League';
import { Sportsbook } from './Sportsbook';
import { formatLeagueName } from '../../util/formatLeagueName';

export function SportsbookPage() {
  const { makeAuthenticatedCall } = useApiCall();
  const [leagues, setLeagues] = useState<League[]>([]);

  useEffect(() => {
    const getLeagues = async () => {
      const res = await makeAuthenticatedCall<League[]>(
        '/v1/league?active=true',
      );
      setLeagues(res);
    };

    getLeagues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Tabs>
      <TabList>
        {leagues.map((league) => (
          <Tab>{formatLeagueName(league.name)}</Tab>
        ))}
      </TabList>

      <TabPanels>
        {leagues.map((league) => (
          <TabPanel>
            <Sportsbook league={league} />
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
}
