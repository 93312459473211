import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, redirect } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import Login from '../Login/Login';
import { Navbar } from '../Navbar/Navbar';

function App() {
  const { auth, setAuth } = useAuth();

  if (!auth) {
    redirect('/login');
  }

  return (
    <div className='wrapper'>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Navbar />} />
          <Route path='/login' element={<Login setAuth={setAuth} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
