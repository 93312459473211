function parseMentionsHelper(message: string): string[] {
  const mentionRegExp =
    /@\[[A-Z0-9._]+\]\((?:user|group|tails|fades):[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}\)/gim;
  const messageMentions = [...message.matchAll(mentionRegExp)].map((m) => m[0]);

  return messageMentions;
}

export function formatMentionText(message: string): string {
  const messageMentions = parseMentionsHelper(message);
  let formattedMessage = message;
  messageMentions.forEach((mentionStr) => {
    const displayComponent = mentionStr.slice(2).split(']')[0];
    formattedMessage = formattedMessage.replace(
      mentionStr,
      `@${displayComponent}`,
    );
  });

  return formattedMessage;
}
