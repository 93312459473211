import { Button, Flex, Spacer, VStack, Text } from '@chakra-ui/react';
import { Game } from '../../models/Game';
import { GameTeams } from './GameTeams';
import { formatOdds } from '../../util/formatOdds';

export function GameComponent(props: { game: Game }) {
  const awayMoneylineOdds = props.game.odds?.moneyline
    ? props.game.odds.moneyline.home.odds
    : 'N/A';
  const homeMoneylineOdds = props.game.odds?.moneyline
    ? props.game.odds.moneyline.away.odds
    : 'N/A';

  const awaySpreadOdds = props.game.odds?.spread
    ? props.game.odds.spread.home.odds
    : 'N/A';
  const homeSpreadOdds = props.game.odds?.spread
    ? props.game.odds.spread.away.odds
    : 'N/A';

  const overTotalOdds = props.game.odds?.total
    ? props.game.odds.total.over.odds
    : 'N/A';
  const underTotalOdds = props.game.odds?.total
    ? props.game.odds.total.under.odds
    : 'N/A';

  return (
    <VStack
      borderWidth={'1px'}
      borderRadius={'lg'}
      padding={'10px'}
      width={'md'}
      boxShadow={'md'}
    >
      <Flex width={'100%'}>
        <GameTeams home={props.game.teams.home} away={props.game.teams.away} />
        <Spacer />
        <Flex fontSize={'sm'} direction={'column'} width={'60%'}>
          <Spacer />
          <Flex direction={'row'}>
            <Button
              width={'20%'}
              size={'sm'}
              isDisabled={awayMoneylineOdds === 'N/A'}
            >
              {awayMoneylineOdds === 'N/A'
                ? awayMoneylineOdds
                : formatOdds(awayMoneylineOdds)}
            </Button>
            <Spacer />
            <Button
              width={'35%'}
              size={'sm'}
              isDisabled={awaySpreadOdds === 'N/A'}
            >
              {awaySpreadOdds === 'N/A'
                ? awaySpreadOdds
                : `${formatOdds(awaySpreadOdds)} ${formatOdds(
                    props.game.odds?.spread?.away.pointSpread,
                  )}`}
            </Button>
            <Spacer />
            <Button
              width={'35%'}
              size={'sm'}
              isDisabled={overTotalOdds === 'N/A'}
            >
              {overTotalOdds === 'N/A'
                ? overTotalOdds
                : `${formatOdds(overTotalOdds)} O${props.game.odds?.total?.over
                    .pointTotal}`}
            </Button>
          </Flex>
          <Spacer />
          <Flex direction={'row'}>
            <Button
              width={'20%'}
              size={'sm'}
              isDisabled={homeMoneylineOdds === 'N/A'}
            >
              {homeMoneylineOdds === 'N/A'
                ? homeMoneylineOdds
                : formatOdds(homeMoneylineOdds)}
            </Button>
            <Spacer />
            <Button
              width={'35%'}
              size={'sm'}
              isDisabled={homeSpreadOdds === 'N/A'}
            >
              {homeSpreadOdds === 'N/A'
                ? homeSpreadOdds
                : `${formatOdds(homeSpreadOdds)} ${formatOdds(
                    props.game.odds?.spread?.home.pointSpread,
                  )}`}
            </Button>
            <Spacer />
            <Button
              width={'35%'}
              size={'sm'}
              isDisabled={underTotalOdds === 'N/A'}
            >
              {underTotalOdds === 'N/A'
                ? underTotalOdds
                : `${formatOdds(underTotalOdds)} U${props.game.odds?.total
                    ?.under.pointTotal}`}
            </Button>
          </Flex>
          <Spacer />
        </Flex>
      </Flex>
      <Text fontSize={'sm'}>
        {props.game.status} @ {props.game.datetime.toString()}
      </Text>
    </VStack>
  );
}
